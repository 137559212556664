export default {
  colors: {
    primary: '#5693B6',
    text: {
      primary: '#000000',
      secondary: '#707070',
    },
  },
  maxWidth: 1400,
  pageTransitionDuration: 1,
};
