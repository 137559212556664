import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TransitioningLink from './transitioning-link';

const StyledLink = styled(TransitioningLink)`
  text-decoration: none;
  position: relative;

  margin: 0;
  @media only screen and (min-width: 690px) {
    margin: 0 0 0 50px;
  }
`;

const Text = styled.div`
  font-weight: 600;
  font-size: 11pt;
  @media only screen and (min-width: 450px) {
    font-size: 13pt;
  }

  text-transform: none;
  text-decoration: none;
  white-space: nowrap;

  transition: color 0.2s;
  color: ${({ active, theme }) => (active ? theme.colors.primary : theme.colors.text.secondary)};

  &:hover {
    cursor: pointer;
    &:before {
      visibility: visible;
      transform: scaleX(1);
    }
  }

  &:before {
    content: "";
    transition: all 0.2s;
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color: ${({ theme }) => theme.colors.primary};

    /* Force line hover effect if links to current page. */
    visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
    transform: ${({ active }) => (active ? 'scaleX(1)' : 'scaleX(0)')};
  }
`;

const HeaderNavLink = ({ children, to, active }) => (
  <StyledLink
    to={to}
    exit={{
      length: 0.4,
    }}
    entry={{
      length: 0.4,
      delay: 0.4,
    }}
  >
    <Text active={active}>{children}</Text>
  </StyledLink>
);

HeaderNavLink.propTypes = {
  children: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

HeaderNavLink.defaultProps = {
  active: false,
};

export default HeaderNavLink;
