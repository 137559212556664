import React from 'react';
import PropTypes from 'prop-types';
import TransitionLink from 'gatsby-plugin-transition-link';

const TransitioningLink = ({ children, to, ...props }) => (
  <TransitionLink
    to={to}
    exit={{
      length: 0.4,
    }}
    entry={{
      length: 0.4,
      delay: 0.4,
    }}
    {...props}
  >
    {children}
  </TransitionLink>
);

TransitioningLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

export default TransitioningLink;
