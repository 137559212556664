import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useLocation } from '@reach/router';
import { TransitionState } from 'gatsby-plugin-transition-link';
import HeaderNavLink from './header-nav-link';

const LINKS = [
  {
    label: 'HOME',
    pathname: '/',
    exact: true,
  },
  {
    label: 'PROJECTS',
    pathname: '/projects',
    exact: true,
  },
  {
    label: 'RESUME',
    pathname: '/resume',
    exact: true,
  },
  {
    label: 'CONTACT',
    pathname: '/contact',
    exact: true,
  },
];

const NavBarContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  z-index: 10;

  /* Fixes issue with nav bars from two separate pages being briefly overlaid on page change. */
  background-color: #FFFFFF;

  padding: 20px;
  @media only screen and (min-width: 450px) {
    padding: 30px;
  }
  @media only screen and (min-width: 690px) {
    padding: 40px;
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  
`;

const NameText = styled.span`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-weight: 600;
  font-size: 13pt;

  /* Override hiding name text if on the home page. */
  display: ${({ visible }) => (visible ? 'inline' : 'none')};
  @media only screen and (min-width: 690px) {
    display: inline;
  }
`;

const NavLinks = styled(motion.div)`
  /* Don't display nav links if set to hidden (occurs when on home page). */
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};

  flex-direction: row;
  justify-content: space-between;

  margin-left: default;
  width: 100%;
  @media only screen and (min-width: 690px) {
    margin-left: auto;
    width: auto;
  }
`;

const HeaderNavBar = () => {
  const location = useLocation();
  // Remove trailing slash if present.
  const pathname = location.pathname.endsWith('/') ? location.pathname.replace(/\/+$/, '') : location.pathname;
  return (
    <NavBarContainer>
      <InnerContainer>
        <NameText visible={pathname === ''}>Jessica Salter</NameText>
        <TransitionState>
          {({ mount, transitionStatus }) => (
            <NavLinks
              transition={{ ease: 'easeInOut', duration: 0.4 }}
              initial={{ opacity: 1 }}
              animate={{ opacity: (transitionStatus === 'entering' && pathname === '') ? 0 : 1 }}
              hidden={(transitionStatus === 'entered' && pathname === '')}
            >
              {LINKS.map((link) => (
                <HeaderNavLink
                  key={link.label}
                  to={link.pathname}
                  active={link.exact ? pathname === link.pathname : pathname.startsWith(link.pathname)}
                >
                  {link.label}
                </HeaderNavLink>
              ))}
            </NavLinks>
          )}
        </TransitionState>
      </InnerContainer>
    </NavBarContainer>
  );
};

HeaderNavBar.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    state: PropTypes.object,
  }).isRequired,
};

export default HeaderNavBar;
