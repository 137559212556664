import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { motion } from 'framer-motion';
import { useLocation } from '@reach/router';
import { Scrollbars } from 'react-custom-scrollbars';
import theme from '../config/theme';
import SEO from '../components/common/seo';
import HeaderNavBar from '../components/common/header-nav-bar';
import '../fonts/proxima-nova/index.css';

const GlobalStyle = createGlobalStyle`
  body {
    padding: 0;
    margin: 0px;
    box-sizing: border-box;
    font-family: 'Proxima Nova', sans-serif;
    background-color: #FFFFFF;
  }

  html, body, #___gatsby, #gatsby-focus-wrapper, .tl-edges, .tl-wrapper {
    height: 100%;
  }
`;

const PageContainer = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const VerticalScrollTrack = styled.div`
  width: 8px !important;
  height: 100%;
  right: 0px;
  border-radius: 10px;
`;

const VerticalScrollThumb = styled(motion.div)`
  background-color: ${theme.colors.text.secondary};
  opacity: 0.5;
  border-radius: 10px;
`;

// TODO: https://webanimation.blog/blog/level30wizards-framer-motion-page-transition
// OR: https://transitionlink.tylerbarnes.ca/docs/installation/

const Layout = ({ children }) => {
  const location = useLocation();
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <SEO location={location} />
        <PageContainer>
          <HeaderNavBar location={location} />
          <Scrollbars
            universal // Run when rendering on both client and server.
            autoHide
            autoHideDuration={600}
            renderTrackVertical={(props) => (
              <VerticalScrollTrack {...props} />
            )}
            renderThumbVertical={(props) => (
              <VerticalScrollThumb {...props} whileHover={{ opacity: 0.7 }} />
            )}
          >
            {children}
          </Scrollbars>
        </PageContainer>
      </ThemeProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
