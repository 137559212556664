import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { TransitionState } from 'gatsby-plugin-transition-link';

const variants = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

const Transition = styled(motion.div)`
  width: 100%;
  flex: 1;
`;

const PageTransition = ({ children }) => (
  <TransitionState>
    {({ transitionStatus }) => (
      <Transition
        variants={variants}
        transition={{ ease: 'easeInOut', duration: 0.4 }}
        initial="hidden"
        animate={['entering', 'entered'].includes(transitionStatus) ? 'visible' : 'hidden'}
      >
        {children}
      </Transition>
    )}
  </TransitionState>
);

PageTransition.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageTransition;
